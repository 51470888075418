<template>
  <div>
    <top-banner></top-banner>
    <main class="main-auto">
      <my-breadcrumb></my-breadcrumb>
      <div class="detail-info">
        <div class="title">
            <div class="flex jc ac">
                <img src="@/assets/body/slide.png" alt="" class="slide" />
                <div class="font_size">{{ title }}</div>
                <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
            </div>
        </div>
        <!-- 酒店详情 -->
        <template v-if="secondMenu === '精品酒店'">
          <div style="text-align: center;">
            <video v-if="video && radio_val == '看视频'"
              ref="video"
              id="video"
              :src="video"
              :autoplay="videolist.autoplay"
              :controls="videolist.controls"
              :controlslist="videolist.controlslist"
              webkit-playsinline="true"
              style="height:720px;z-index:10"
            ></video>
          </div>
          <div v-if="imgs.length !== 0 && radio_val == '看图片'">
            <el-carousel height="720px"
            style="margin-top:40px" indicator-position="none"
            :interval="4000" @change="carouselHand" ref="carousel_hotel">
              <el-carousel-item v-for="(item, index) in imgs" :key="index">
                  <div
                  :style="{ backgroundImage: 'url(' + item + ')' }"
                  class="images"
                  ></div>
              </el-carousel-item>
            </el-carousel>
            <div
            style="color:#333333;font-size:24px;font-weight:600;text-align:center;margin:20px 0 30px"
            > {{bannerIndex + 1}} / {{imgs.length}}</div>
            <div class="flex ac" style="overflow-x: auto">
              <div
              :class="['pre-item', bannerIndex === index ? 'img-active' : '']"
              v-for="(item, index) in imgs" 
              :key="index"
              @click="setActiveItem(index)"
              >
                  <div
                    :style="{ backgroundImage: 'url(' + item + ')' }"
                    class="pre-images"
                  ></div>
              </div>
            </div>
          </div>
          <div
            v-if="radio_val"
            style="position: absolute;left: 50%;transform: translate(-50%,-20%);z-index:100">
              <el-radio-group v-model="radio_val" v-if="video && imgs" @change="handleChange">
              <el-radio-button label="看视频"></el-radio-button>
              <el-radio-button label="看图片"></el-radio-button>
            </el-radio-group>
          </div>
          <div style="overflow:auto;margin-top:40px">
              <div class="content-f" v-html="htmlData"></div>
          </div>
        </template>
        <template v-else-if="secondMenu === '案例详情'">
          <div style="overflow:auto">
              <div class="content-f" v-html="htmlData"></div>
              <!-- <div
              v-if="img"
              class="img-box"
              :style="{ backgroundImage : 'url(' + img +')' }">
              </div> -->
          </div>
        </template>
        <!-- 租赁详情 -->
        <template v-else-if=" firstMenu === '特色服务'"> 
          <div class="font_24 text_3" style="line-height:25px;letter-spacing:2px;" v-html="htmlData"></div>
          <!-- <div
          :style="{ backgroundImage: 'url(' + img + ')' }"
          style="width: 842px;height: 455px;margin: 73px auto;"
          class="images"
          ></div> -->
          <div v-if="caselist.length !== 0">
            <header-title
              title="客户案例"
              height="120px"
            ></header-title>
            <div class="container flex fw">
              <div
                @click="goPage(item,'case')"
                class="list-item"
                v-for="(item, index) of caselist"
                :key="index"
              >
                <div style="overflow:hidden">
                  <div
                  :style="{ backgroundImage: 'url(' + item.domain_image + ')' }"
                  class="images large"
                  ></div>
                </div>
                <div class="list-item-content">
                  <div class="list-item-title only">
                    {{ item.name }}
                  </div>
                  <div class="list-item-desc double">
                    {{ item.synopsis }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 默认详情页 -->
        <template v-else>
          <div style="overflow:auto">
              <div class="content-f" v-html="htmlData"></div>
              <!-- <div
              v-if="img"
              class="img-box"
              :style="{ backgroundImage : 'url(' + img +')' }">
              </div> -->
          </div>
        </template>
      </div>    
    </main>
  </div>
</template>

<script>
import MyBreadcrumb from "@/components/Breadcrumb.vue";
import TopBanner from "@/components/TopBanner.vue";
import headerTitle from "@/components/header_title";
import { mapGetters } from "vuex";
var util = require("@/utils/util");
import carouselItem from "@/components/carousel/src/item.vue";
import Swiper from "swiper";
import activity from "@/components/Activity_new";
export default {
  components: {
    activity,
    MyBreadcrumb,
    TopBanner,
    headerTitle,
  },
  data() {
    return {
      firstMenu: "",
      radio_val: "",
      videolist:{
        autoplay:false, // 自动播放
        controls:'controls', //操作
        controlslist:'nodownload  noplaybackrate' 
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      }, //video配置
      caselist: [],
      id: "",
      title: "",
      htmlData: "",
      img: "",
      imgs: "",
      video: "",
      bannerImg: [],
      infoSwiper: null,
      articleIdx: 0,
      show: "",
      info: [],
      activity: [],
      route: [],
      news: [],
      choice_zixun: {},
      banner_info: "",
      LangeList: [],
      infoList: [],
      commonList: [],
      noticList: [],
      activityList: [],
      scenicList: [],
      showTopButton: false,
      bannerSwiper: "",
      bannerIndex: "",
    }
  },
  async mounted() {
    this.initSwiper();
    this.id = this.$route.query.id
    this.title = this.$route.query.title
    this.htmlData = this.$route.query.htmlData
    if(this.$route.query.img) {
      this.img = this.$route.query.img
    }
    if(this.$route.query.imgs) {
      this.imgs = this.$route.query.imgs
    }
    if(this.$route.query.video) {
      this.video = this.$route.query.video
    }
    if(this.$route.query.firstMenu) {
      this.firstMenu = this.$route.query.firstMenu
    }
    if(this.imgs) {
      this.radio_val = '看图片'
    } else if(this.video) {
      this.radio_val = '看视频'
    } else {
      this.radio_val = null
    }
    await this.commonGetList("os_e_case");
  },
  methods: {
    handleChange(v) {
      this.radio_val = v
    },
    // 实时获取下标
    carouselHand(ev) {
      this.bannerIndex = ev
    },
    // 手动调整activeItem
    setActiveItem(i) {
      this.$refs.carousel_hotel.setActiveItem(i)
    },
    commonGetList(type) {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/commonGetList",
            data: { table: type, especially_id: this.id },
          })
          .then((res) => {
            if (res.code) {
              this.caselist = res.data.list
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    goPage(value,type) {
      if(type && type === 'case') {
        const nLoc = this.nowLocation + ' > ' + '客户案例';
        this.$store.dispatch("setNowLocation", nLoc);
      }
      this.$router.push({
        path: "/special/case",
        query: { 
          id: value.id,
          title: value.name,
          htmlData: value.content,
          img: value.domain_image,
        },
      });
    },
    initSwiper() {
      this.infoSwiper = new Swiper(".mySwiper", {
        slidesPerView: 3,
        spaceBetween: 25,
        autoplay: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
  watch: {
      bennrtList() {
          const ts = this;
          ts.$nextTick(() => {
          });
      },
  },
  computed: {
      ...mapGetters(["bennrtList","secondMenu","nowLocation"]),
  },
};
</script>

<style lang="scss" scoped>
  .container {
    margin-bottom: 191px;
    .list-item-content {
      padding: 17px 10px 25px;
    }
    .list-item:hover {
      box-shadow: 0px 0px 61px 1px rgba(0, 0, 0, 0.17);
    }
    .list-item {
      width: calc(24% - 16px);
      // height: 403px;
      margin-right: 24px;
      margin-bottom: 24px;
      cursor: pointer;
      .images {
        width: 100%;
        height: 257px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px;
      }
      .list-item-title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .list-item-open-time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 8px;
      }
      .list-item-desc {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
      }
      .list-item-label {
        padding: 4px 8px;
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--bg-color);
        border: 1px solid var(--bg-color);
        border-radius: 12px;
      }
      .query-detail {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--bg-color);
      }
      .list-item-tags {
        padding: 1px 2px;
        width: 68px;
        height: 22px;
        line-height: 22px;
        border-radius: 4px;
        font-size: 12px;
        color: #c8ab73;
        text-align: center;
        border: 1px solid #c8ab73;
      }
    }
  }
  .pre-item {
    margin-right: 24px;
    width: 220px;
    min-width: 220px;
    height: 220px;
  }
  .img-active {
    border: 5px solid #C8AB73;
  }
  .pre-images {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .main-auto {
    margin: 0 auto;
  }
  .article{
    width: 842px;
    height: 455px;
    margin: 73px auto 0;
    background-color: beige;

  }
  .images {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
 .section {
      margin-left: 0 !important;
  }
  .detail-info {
    padding: 40px 70px 0;
    margin-bottom: 70px;
  }
  .detail-info >>> img {
      max-width: 1000px !important;
  }
  .title {
      font-size: 28px;
      font-weight: bold;
      color: #894824;
      margin: 60px 0;
  }
  .content-f {
      color: #333333;
      font-size: 24px;
      margin: 40px 0 70px;
  }
  .img-box {
      width: 842px;
      height: 455px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
      margin: 72px auto;
  }
</style>